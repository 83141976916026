import React from 'react'

const Icon = ({ color }) => (
  <svg xmlns="https://www.w3.org/2000/svg" viewBox="0 0 207.002 207.002">
    <path
      fill={color}
      d="M199.502,0h-85.335c-1.989,0-3.897,0.79-5.304,2.197L2.197,108.865c-2.929,2.929-2.929,7.678,0,10.606l85.334,85.334
      c1.465,1.464,3.385,2.197,5.304,2.197c1.919,0,3.839-0.732,5.304-2.197L204.806,98.137c1.406-1.406,2.196-3.314,2.196-5.303V7.5
      C207.002,3.358,203.645,0,199.502,0z M192.002,89.728l-99.168,99.168l-74.728-74.728L117.274,15h74.729V89.728z"
    />
    <path
      fill={color}
      d="M156.835,72.752c6.032,0,11.704-2.349,15.971-6.615c8.805-8.806,8.804-23.134-0.002-31.94
      c-4.266-4.265-9.938-6.614-15.97-6.614s-11.704,2.349-15.97,6.615c-8.805,8.806-8.805,23.134,0,31.94
      C145.13,70.403,150.802,72.752,156.835,72.752z M151.472,44.803c1.432-1.432,3.337-2.221,5.362-2.221
      c2.026,0,3.932,0.789,5.363,2.221c2.958,2.958,2.958,7.77,0.002,10.727c-1.433,1.433-3.338,2.222-5.364,2.222
      s-3.932-0.789-5.363-2.221C148.515,52.573,148.515,47.761,151.472,44.803z"
    />
  </svg>
)

export default Icon
